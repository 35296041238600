export type Discount = {
    /** id will exist if the object is persisted in the BE */
    id?: number;
    name: string;
    // type: DiscountType;
    type: string;
    amount: number;
    maxDiscount: string | null;
};

export type Charge = {
    /** id will exist if the object is persisted in the BE */
    id?: number;
    name: string;
    // type: ChargeType;
    type: string;
    amount: number;
    minCharge: string | null;
    maxCharge: string | null;
};

export type NonEmptyString<T> = T extends '' ? never : T;

export type Appointment = {
    id: number;
    guestUser: GuestUser | null;
    user: User | null;
    customer: Customer;
    alternativeTransportationType: TransportationType | null;
    repairOrder: RepairOrder | null;
    dateCreated: Date;
    date: Date;
    textUpdates: boolean;
    emailUpdates: boolean;
    vehicleYear: number;
    vehicleModel: string;
    vehicleMake: string;
    miles: number | null;
    vin: string | null;
    customerNotes: string | null;
    internalNotes: string | null;
    deleted: boolean;
    appointmentServices: AppointmentService[];
    otherRequest: string | null;
    checkIn: CheckIn1;
    dmsId: string | null;
    vehicleDmsId: string | null;
    customerInformation: Customer; //todo: check for these next three enteties
    servicesInformation: AppointmentService[];
    vehicleInformation: CustomerVehicle;
};

export enum ServiceLanes {
    body = 'body',
    express = 'express',
    main = 'main',
}

export type AppointmentCode = {
    id: number;
    title: string;
    code: string;
    description: string;
    laborHours: number;
    customerHours: number;
    cost: number;
    serviceLane: string;
    deleted: boolean | null;
    internal: boolean;
    sortOrder: number | null;
};

export type AppointmentCodeOperationCode = {
    id: number;
    appointmentCode: AppointmentCode | null;
    operationCode: OperationCode | null;
};

export type AppointmentService = {
    id: number;
    appointment: Appointment;
    appointmentCode: AppointmentCode;
    customerNotes: string | null;
    internalNotes: string | null;
    code: string | null;
    hours: number;
    dmsId: number | null;
};

export type AppointmentCodeGroup = {
    id: number;
    name: string;
    sortOrder: number;
    appointmentCodes: AppointmentCode[] | null;
};

export type BdcLabel = {
    id: number;
    name: string;
};

export type BdcPhase = {
    id: number;
    name: string;
    color: string | null;
    sortOrder: number;
    promptNotes: boolean;
    hoursToExpire: number | null;
    textColor: string | null;
    requireAppointment: boolean;
};

export type BdcQuickSmsCreate = {
    id: number;
    bdcQuickSmsGroup: BdcQuickSmsGroup | null;
    message: string;
};

export type BdcQuickSmsGroup = {
    id: number;
    name: string;
    bdcQuickSms: BdcQuickSmsCreate[] | null;
};

export type BdcTask = {
    id: number;
    bdcPhase: BdcPhase | null;
    customer: Customer | null;
    user: User | null;
    labels: string;
    dateCreated: Date;
    appointment: Appointment | null;
    appointmentDate: Date | null;
    dateDeclined: Date | null;
    declinedReason: string | null;
    dateExpired: Date | null;
    deleted: boolean;
    bdcTaskInteractions: BdcTaskInteraction[];
    totalDeclined: number;
    unread: number;
    bdcTaskPhaseTimestamp: Date | null;
    repairOrder: RepairOrder | null;
    notes: BdcTaskNote[] | null;
    objective: string | null;
};

export type BdcTaskInteraction = {
    id: number;
    user: User | null;
    type: string;
    date: Date;
    bdcPhaseDate: Date | null;
    description: string | null;
};

export type BdcTaskNote = {
    id: number;
    note: string;
    dateCreated: Date;
    user: User | null;
};

export type BdcTaskGenerator = {
    id: number;
    technician: User | null;
    advisor: User | null;
    name: string | null;
    recurring: boolean;
    visitDaysStart: number | null;
    visitDaysEnd: number | null;
    anyFailedMpiItems: boolean;
    failedMpiItemIds: string | null;
    anyWarningMpiItems: boolean;
    warningMpiItemIds: string | null;
    anyDeclinedOperationCodes: boolean;
    declinedOperationCodeIds: string | null;
    anyApprovedOperationCodes: boolean;
    approvedOperationCodeIds: string | null;
    vehicleYearStart: number | null;
    vehicleYearEnd: number | null;
    vehicleMilesStart: number | null;
    vehicleMilesEnd: number | null;
    vehicleMakes: string | null;
    vehicleModels: string | null;
    labels: string;
    deleted: boolean;
    objective: string | null;
};

export type BdcExpress = {
    id: number;
    name: string;
    color: string;
    sortOrder: number;
    promptNotes: boolean;
    hoursToExpire: number;
    bdcExpressTasks: any[];
    textColor: string;
    requireAppointment: boolean;
};

export type Campaign = {
    id: number;
    name: string;
    type: string;
    message: string;
    date: Date;
    completedDate: Date | null;
    scheduledDate: Date | null;
    customerCount: number;
    customerCampaigns: CustomerCampaign[] | null;
    sms: any[] | null;
    url: string | null;
    shortenedUrl: string | null;
    filters: string;
    image: string | null;
    price: number | null;
    costPerMessage: number | null;
    sent: any[];
    unsent: any[];
};

export type Coupon = {
    id: number;
    title: string;
    image: string | null;
};

//TODO: there are currently two types of CheckIn, will change these to be more specific once know more info
export type CheckIn1 = {
    image: string | null;
    price: number | null;
    costPerMessage: number | null;
    sent: any[];
    unsent: any[];
};

export type CheckIn2 = {
    id: number;
    appointment: Appointment;
    customer: Customer;
    user: User;
    customerVehicle: CustomerVehicle;
    repairOrder: RepairOrder;
    date: string;
    guestUser: GuestUser;
};

export type CheckInMedia = {
    id: number;
    checkIn: CheckIn2;
    isVideo: boolean;
    mediaUrl: string;
    date: string;
};

export type CheckInOperationCode = {
    id: number;
    user: User;
    checkIn: CheckIn2;
    laborRate: LaborRate;
    operationCode: OperationCode;
    date: string;
};

export type Customer = {
    id: number;
    name: string;
    phone: string | null;
    mobileConfirmed: boolean;
    email: string | null;
    doNotContact: boolean;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    customerRewards: CustomerRewards;
    marketingOptOut: boolean;
    customerVehicles: CustomerVehicle[] | null;
    dateLastContacted: Date | null;
    dateLastVisited: Date | null;
    unread: number;
    dmsId: string | null;
};

export type CustomerNote = {
    id: number;
    user: User | null;
    note: string;
    date: Date;
};

export type CustomerVehicle = {
    id: number;
    year: number;
    make: string;
    model: string;
    miles: number | null;
    vin: string | null;
};

export type CustomerCampaign = {
    id: number;
    customer: Customer;
    campaign: Campaign;
    failure: boolean;
    sent: boolean;
    failureReason: string | null;
    dateSent: string | null;
    repairOrder: RepairOrder | null;
};

export type CustomerRewards = {
    dateFirstEarned: string;
    dateLastEarned: string;
    points: number;
    totalEarned: number;
    totalSpent: number;
    rewards: number;
};

export type Demo = {
    id: number;
    createdByUser: User | null;
    assignedUser: User | null;
    customer: Customer;
    repairOrder: RepairOrder;
    referenceDemo: Demo | null;
    name: string;
    phone: string;
    email: string | null;
    status: string;
    dateCreated: Date;
    dateUpdated: Date;
};

export type DropdownOption = {
    label: string;
    value: any;
};

export type Event = {
    id: number;
    name: string;
    className: string;
    entityId: number;
    eventType: string;
    date: Date;
    user: User | null;
};

export type Estimate = {
    notes: any[];
    laborTime: number;
    requiredSkill: any;
    serviceType: string;
    severeCondition: boolean;
    name: string;
    frequencyType: string;
    intervalMiles: number;
    intervalMonths: number;
};

export type GuestUser = {
    id: number;
    dateCreated: Date | null;
    totalRequestCount: number | null;
    updateRequestCount: number | null;
    appointments: Appointment[];
};

export type InternalMessage = {
    id: number;
    from: User;
    to: User;
    message: string;
    date: Date;
    isRead: boolean;
};

export type KbbModel = {
    id: number;
    modelId: number;
    model: string;
    makeId: number;
    make: string;
    year: number;
};

export type KbbMake = {
    id: number;
    year: number;
    makeId: number;
    make: string;
};

export type KbbYear = {
    id: number;
    year: number;
};

interface PaginatedResponse {
    currentPage: number;
    totalPages: number;
    totalResults: number;
}

interface PaginatedResponse {
    currentPage: number;
    totalPages: number;
    totalResults: number;
}

export type LaborRate = {
    id: number;
    name: string;
    rate: string;
    code: string;
    useMatrix: boolean;
    calculateSupplies: boolean;
    laborRateMatrix: LaborRateMatrix | null;
    defaultNoCharge: boolean;
    dmsCode: string | null;
};

export interface LaborRateResponse extends PaginatedResponse {
    results: LaborRate[];
}

export type LaborRateMatrix = {
    id: number;
};

export type LaborRateMatrixValue = {
    id: number;
    hours: number;
    price: number | null;
};

export enum MessageTypes {}
//TODO: ask backend for types

export type MotorEstimate = any;

export type MotorEstimatedWorkTime = {
    baseLaborTime: number;
    name: string;
    applicationId: string;
    baseLaborTimeDescription: string;
    allLaborTime: number;
    allLaborTimeDescription: string;
    additionalLaborTime: number;
    additionalLaborDescription: any;
    laborTimeInterval: string;
    notes: string;
    requiredSkill: any;
    subGroupId: number;
    subGroupName: string;
    groupId: number;
    groupName: string;
    systemId: number;
    systemName: string;
    optional: any[];
    qualifiers: any[];
};

export type MyLoan = {
    id: number;
    repairOrder: RepairOrder;
    amount: any | null; // type is supposed to be "money"
    linkHash: string;
    terms: string | null;
    dateSent: Date | null;
    dateViewed: Date | null;
    dateCompleted: Date | null;
    status: string;
    loanId: string | null;
    applicationUrl: string | null;
    dateCreated: Date | null;
    actualAmount: any | null; // type is supposed to be "money"
    loanStatus: string | null;
    transactionId: string | null;
    transactedAmount: any | null; // type is supposed to be "money"
};

export type MpiTemplate = {
    id: number;
    name: string;
    active: boolean;
    mpiGroups: MpiGroup[];
};

export type MpiGroup = {
    id: number;
    name: string;
    active: boolean;
    mpiItems: MpiItem[];
};

export type MpiItem = {
    id: number;
    mpiGroup: MpiGroup;
    name: string;
    hasRange: boolean;
    rangeMaxiumum: number | null;
    rangeUnit: string | null;
};

export type MpiItemOperationCode = {
    id: number;
    mpiItem: MpiItem;
    operationCode: OperationCode;
};

export type OperationCode = {
    id: number;
    code: string;
    description: string | null;
    laborHours: number;
    laborPrice: string;
    laborTaxable: boolean;
    partsPrice: number;
    partsTaxable: boolean;
    menuPricing: boolean | null;
    chargeSupplies: boolean;
    usedCount: number;
};

export type Part = {
    id: number;
    number: string | null;
    name: string;
    bin: string | null;
    available: number | null;
    price: number | null;
    replacesPartNumber: string | null;
    cost: string | null;
    list: string | null;
    trade: string | null;
    comp: string | null;
    exchange: string | null;
    price6: string | null;
    price7: string | null;
    price8: string | null;
    price9: string | null;
    price10: string | null;
    unit: string | null;
    core: string | null;
    retail: string | null;
    source: string | null;
    manufacturerCode: string | null;
    usedCount: number;
    vendor: string | null;
};

export type PartMatrix = {
    id: number;
    name: string;
    partMatrixValues: PartMatrixValue[] | null;
};

export type PartMatrixValue = {
    id: number;
    partMatrx: PartMatrix;
    min: string;
    max: string | null;
    priceField: string;
    priceBreak: string | null;
    markupPercentage: string;
    discountPercentage: string;
};

export type PartSource = {
    id: number;
    source: string;
};

export type PartSourceLaborRateMatrix = {
    id: number;
    partSource: PartSource;
    laborRate: LaborRate;
    partMatrix: PartMatrix;
};

export type Payment = {
    id: number;
    type: string | null;
    customer: Customer;
    number: string | null;
    amount: any;
    refundedAmount: any | null;
    linkHash: string | null;
    transactionId: string | null;
    referenceId: string | null;
    notes: string | null;
    description: string | null;
    status: string;
    cardType: string | null;
    cardNumber: string | null;
    dateCreated: Date;
    paymentInteractions: PaymentInteraction[];
    dmsReferenceId: string | null;
    dmsId: string | null;
    tenderType: string | null;
    pinStatement: string | null;
    dedicatedFileName: string | null;
    applicationLabel: string | null;
};

export type PaymentInteraction = {
    id: number;
    type: string;
    date: Date;
};

export type PaymentRefund = {
    id: number;
    payment: Payment;
    transactionId: string;
    user: User;
    refundAmount: any | null; // type is supposed to be "money"
    date: Date;
};

export type PaymentTerminal = {
    id: number;
    name: string;
    deviceId: string;
};

export type PriceMatrix = {
    id: number;
    hours: number;
    price: number | null;
};

export type PredefinedCampaignFilter = {
    id: number;
    name: string;
    filters: string;
};

export enum QuoteStatuses {
    NOT_STARTED = 'Not Started',
    ADVISOR_IN_PROGRESS = 'Advisor In Progress',
    TECHNICIAN_IN_PROGRESS = 'Technician In Progress',
    PARTS_IN_PROGRESS = 'Parts In Progress',
    SENT = 'Sent',
    VIEWED = 'Viewed',
    COMPLETED = 'Completed',
    CONFIRMED = 'Confirmed',
}

export enum QuotingTabs {
    EDIT_ADDITIONAL = 'EDIT_ADDITIONAL',
    EDIT_PRE_APPROVED = 'EDIT_PRE_APPROVED',
    QUOTE_VIEW = 'QUOTE_VIEW',
    RO_DETAILS = 'RO_DETAILS',
}

export type QuoteDiscount = {
    id: number;
    name: string | null;
    type: string | null;
    amount: string | null;
    maxDiscount: string | null;
};

export type QuoteMiscCharge = {
    id: number;
    name: string | null;
    type: string | null;
    amount: string | null;
    minCharge: string | null;
    maxCharge: string | null;
};

export type ReconditioningPhase = {
    id: number;
    name: string;
    color: string;
    textColor: string | null;
    sortOrder: number;
    special: boolean;
};

export type RepairOrderCustomer = {
    id: number;
    repairOrder: RepairOrder;
    customer: Customer;
};

export type RepairOrder = {
    id: number;
    primaryCustomer: Customer;
    primaryTechnician: User | null;
    primaryAdvisor: User | null;
    primaryPartsAdvisor: User | null;
    quoteInProgress: User | null;
    number: string;
    videoStatus: string;
    mpiStatus: string;
    quoteStatus: string;
    paymentStatus: string;
    startValue: number | null;
    finalValue: number | null;
    approvedValue: number | null;
    dateCreated: Date;
    dateClosed: Date | null;
    waiter: boolean;
    pickupDate: Date | null;
    linkHash: string;
    year: number | null;
    make: string | null;
    model: string | null;
    miles: number | null;
    vin: string | null;
    internal: boolean;
    dmsKey: string | null;
    waiverSignature: string | null;
    waiverVerbiage: string | null;
    upgradeQueue: boolean;
    repairOrderMPI: RepairOrderMpi | null;
    notes: RepairOrderNote[] | null;
    repairOrderQuote: RepairOrderQuote;
    repairOrderReview: RepairOrderReview;
    repairOrderCustomers: RepairOrderCustomer[] | null;
    repairOrderTeams: RepairOrderTeam[] | null;
    mpiStatusTimestamp: Date | null;
    videoStatusTimestamp: Date | null;
    quoteStatusTimestamp: Date | null;
    paymentStatusTimestamp: Date | null;
    dateQuoteInProgress: Date | null;
    hasVideo: boolean;
    rewardsUsed: number;
    appointment: Appointment | null;
    tagId: string | null;
    pickListPartStatus: string | null;
    dateOfSale: Date | null;
    promisedDate: Date | null;
    pickListPartStatusTimestamp: Date | null;
    servicePhase: ServicePhase | null;
    servicePhaseTimestamp: Date | null;
    repairOrderServicePhaseInteractions:
        | RepairOrderServicePhaseInteraction[]
        | null;
    bdcTask: BdcTask;
    reconditioningPhase: ReconditioningPhase | null;
    reconditioningPhaseTimestamp: Date | null;
    repairOrderReconditioningPhaseInteractions:
        | RepairOrderReconditioningPhaseInteraction[]
        | null;
    customerCampaign: CustomerCampaign | null;
    hours: string | null;
    serialNumber: string | null;
    objectNumber: string | null;
    serviceLane: string | null;
    vehicleDmsId: string | null;
};

export type RepairOrderImage = {
    id: number;
    repairOrder: RepairOrder;
    name: string;
    image: string;
    note: string | null;
    internal: boolean | null;
    appointment: Appointment;
    approvedValue: number;
    bdcTask: BdcTask;
    checkIn: CheckIn1;
    customerCampaign: CustomerCampaign;
    dateClosed: string;
    dateCreated: string;
    dateOfSale: string;
    dateQuoteInProgress: string;
    dmsKey: string;
    finalValue: number;
    hours: string;
    linkHash: string;
    make: string;
    miles: number;
    model: string;
    mpiStatus: string;
    mpiStatusTimestamp: string;
    notes: RepairOrderNote[];
    number: string;
    objectNumber: string;
    paymentStatus: string;
    paymentStatusTimestamp: string;
    pickListPartStatus: string;
    pickListPartStatusTimestamp: string;
    pickupDate: string;
    primaryAdvisor: User;
    primaryCustomer: Customer;
    primaryPartsAdvisor: User;
    primaryTechnician: User;
    promisedDate: string;
    quoteInProgress: User;
    quoteStatus: string;
    quoteStatusTimestamp: string;
    reconditioningPhase: ReconditioningPhase;
    reconditioningPhaseTimestamp: string;
    repairOrderCustomers: any[]; // TODO - are these actually RepairOrderCustomers or just Customers?
    repairOrderMPI: RepairOrderMpi;
    repairOrderQuote: RepairOrderQuote;
    repairOrderQuoteId: number | null;
    repairOrderReconditioningPhaseInteractions: RepairOrderReconditioningPhaseInteraction[];
    repairOrderReview: RepairOrderReview;
    repairOrderServicePhaseInteractions: RepairOrderServicePhaseInteraction[];
    repairOrderTeams: RepairOrderTeam[];
    rewardsUsed: number;
    serialNumber: string;
    serviceLane: string;
    servicePhase: ServicePhase;
    servicePhaseTimestamp: string;
    startValue: number;
    tagId: string;
    upgradeQueue: boolean;
    videoStatus: string;
    videoStatusTimestamp: string;
    vin: string;
    waiter: boolean;
    waiverSignature: string;
    waiverVerbiage: string;
    year: number;
};

export type RepairOrderInteraction = {
    id: number;
    user: User | null;
    customer: Customer | null;
    type: string;
    date: Date;
};

export type RepairOrderMpi = {
    id: number;
    repairOrder: RepairOrder;
    dateCompleted: Date | null;
    dateSent: Date | null;
    results: string | null;
    repairOrderMPIInteractions: RepairOrderMpiInteraction[] | null;
    dateViewed: Date | null;
};

export type RepairOrderMpiInteraction = {
    id: number;
    user: User | null;
    customer: Customer | null;
    type: string;
    date: Date;
};

export type RepairOrderNote = {
    id: number;
    note: string | null;
    dateCreated: Date;
    user: User | null;
};

export type RepairOrderQuote = {
    id: number;
    dateCreated: Date;
    dateSent: Date | null;
    dateCustomerViewed: Date | null;
    dateCompleted: Date | null;
    dateConfirmed: Date | null;
    repairOrderQuoteRecommendations: RepairOrderQuoteRecommendation[];
    status: QuoteStatuses;
    subtotal: number | null;
    tax: number | null;
    processingFee: number | null;
    total: number | null;
    customerTotal: number | null;
    customerTotalTax: number | null;
    partsTax: number | null;
    laborTax: number | null;
    repairOrderQuoteLogs: RepairOrderQuoteLog[];
    deleted: boolean;
    supplies: number | null;
    customer: Customer | null;
    year: number | null;
    make: string | null;
    model: string | null;
    vin: string | null;
    motorBaseVehicleId: string | null;
    motorSubModelId: string | null;
    motorEngineId: string | null;
    repairOrderQuoteMiscCharges: Charge[];
    repairOrderQuoteDiscounts: Discount[];
    discountAmount: number | null;
    miscChargeAmount: number | null;
    laborPrice: number | null;
    partsPrice: number | null;
    laborHours: number | null;
    approvedLaborPrice: number | null;
    approvedLaborHours: number | null;
    approvedPartsPrice: number | null;
    approvedSuppliesPrice: number | null;
    approvedTax: number | null;
    approvedTotal: number | null;
    approvedCustomerTotal: number | null;
    approvedDiscountTotal: number | null;
    approvedMiscChargesTotal: number | null;
    // these last 3 aren't in the php file from the backend so will need to confirm
    repairOrderId: number;
    totalDiscounts: number;
    totals: {
        approved: RepairOrderQuoteTotals;
        preApproved: RepairOrderQuoteTotals;
        additional: RepairOrderQuoteTotals;
        all: RepairOrderQuoteTotals;
    };
    eTag: string | null;
};

export type RepairOrderQuoteDiscount = {
    id: number;
    repairOrderQuote: RepairOrderQuote | null;
    repairOrderQuoteRecommendation: RepairOrderQuoteRecommendation | null;
    name: string;
    amount: number;
    discountAmount: number;
    type: string | null;
    maxDiscount: string | null;
};

export type MotorPart = {
    price: number;
    partNumber: string;
    name: string;
    position: string;
    manufacturer: any;
    quantity: number;
    qualifiers: Qualifier[];
    applicationId: string;
};

export type Qualifier = {
    description: string;
    active: boolean;
};

export type RepairOrderQuoteApprovalStatus = boolean | null;

export type RepairOrderQuoteLog = {
    id: number;
    user: User | null;
    customer: Customer | null;
    date: string;
    data: string | null; // JSON
};

export type RepairOrderQuoteRecommendation = {
    id: number;
    repairOrderQuote: RepairOrderQuote;
    operationCode: string;
    description: string | null;
    preApproved: boolean | null;
    approved: boolean | null;
    partsPrice: number | null;
    laborPrice: number | null;
    laborTax: number | null;
    partsTax: number | null;
    laborHours: number | string | null;
    laborTaxable: boolean | null;
    partsTaxable: boolean | null;
    notes: string | null;
    repairOrderQuoteRecommendationParts:
        | RepairOrderQuoteRecommendationPart[]
        | null;
    noCharge: boolean | null;
    laborRate: LaborRate | null;
    menuPricing: boolean;
    chargeSupplies: boolean;
    complaint: string | null;
    cause: string | null;
    correction: string | null;
    discountAmount: number | null;
    miscChargeAmount: number | null;
    repairOrderQuoteMiscCharges: RepairOrderQuoteMiscCharge[] | null;
    repairOrderQuoteDiscounts: RepairOrderQuoteDiscount[] | null;
    sortOrder: number | null;
    motorApplicationId: number | null;
    subtotal: number | null;
    total: number | null;
    dmsId: string | null;
    // these last 2 aren't in the php file from the backend so will need to confirm
    laborRateId?: number; // for create
    repairOrderQuoteId: number; // for create
};

export interface RepairOrderQuoteRecommendationCreate
    extends Omit<
        RepairOrderQuoteRecommendation,
        | 'approved'
        | 'discountAmount'
        | 'dmsId'
        | 'id'
        | 'laborPrice'
        | 'laborRate'
        | 'laborTax'
        | 'miscChargeAmount'
        | 'partsPrice'
        | 'partsTax'
        | 'repairOrderQuoteRecommendationParts'
        | 'repairOrderQuoteDiscounts'
        | 'repairOrderQuoteMiscCharges'
        | 'sortOrder'
        | 'repairOrderQuote'
        | 'subtotal'
    > {
    approved?: boolean;
    laborRateId?: number;
    operationCodeId?: number;
    repairOrderQuoteId: number;
}

export type RepairOrderQuoteRecommendationPart = {
    id: number;
    repairOrderRecommendation: RepairOrderQuoteRecommendation;
    part: Part | null;
    number: string | null;
    name: string | null;
    price: number | null;
    quantity: number | null;
    totalPrice: number | null;
    bin: string | null;
    expectedArrival: string | null;
    expectedArrivalUpdatedDate: Date | null;
    ordered: boolean;
    arrived: boolean;
    partCondition: string | null;
    manufacturerCode: string | null;
    motorApplicationId: number | null;
    vendor: string | null | null;
};

export type RepairOrderQuoteTotals = {
    customerTotal: number;
    customerTotalTax: number;
    discountAmount: number;
    discounts: number;
    labor: number;
    laborHours: number;
    laborTax: number;
    miscChargeAmount: number;
    miscCharges: number;
    parts: number;
    partsTax: number;
    processingFee: number;
    subTotal: number;
    supplies: number;
    suppliesTax: number;
    tax: number;
    total: number;
};

export type RepairOrderReconditioningPhaseInteraction = Record<string, never>;

export type RepairOrderReview = {
    id: number;
    repairOrder: RepairOrder;
    status: string;
    dateSent: Date | null;
    dateViewed: Date | null;
    dateCompleted: Date | null;
    rating: string | null;
    platform: string | null;
};

export type RepairOrderServicePhaseInteraction = Record<string, never>;

export type RepairOrderTeam = {
    id: number;
    user: User | null;
    repairOrder: RepairOrder;
};

export type RepairOrderPayment = {
    id: number;
    repairOrder: RepairOrder | null;
    amount: any; // type is supposed to be "money"
    transactionId: string | null;
    refundedAmount: string | null;
    dateCreated: Date;
    dateSent: Date | null;
    dateViewed: Date | null;
    datePaid: Date | null;
    dateConfirmed: Date | null;
    dateRefunded: Date | null;
    dateDeleted: Date | null;
    cardType: string | null;
    cardNumber: string | null;
    status: string | null;
    interactions: RepairOrderInteraction[];
    dmsId: string | null;
    tenderType: string | null;
    pinStatement: string | null;
    dedicatedFileName: string | null;
    applicationLabel: string | null;
    refundTransactionId: string | null;
};

export type RepairOrderPaymentRefund = {
    id: number;
    repairOrderPayment: RepairOrderPayment;
    transactionId: string;
    user: User | null;
    refundAmount: any; // type is supposed to be "money"
    date: Date;
};

export type RepairOrderPickListPart = {
    id: number;
    operationCode: string;
    partNumber: string;
    partName: string;
    partBin: string | null;
    price: number | null;
    quantity: number;
    status: string | null;
    deleted: boolean;
};

export type RepairOrderPickListPartInteraction = {
    id: number;
    user: User | null;
    custmoer: Customer | null;
    date: Date;
    status: string;
};

export type RepairOrderQuoteInteraction = {
    id: number;
    repairOrderQuote: RepairOrderQuote;
    user: User | null;
    customer: Customer | null;
    type: string;
    date: Date;
};

export type RepairOrderQuoteMiscCharge = {
    id: number;
    repairOrderQuote: RepairOrderQuote | null;
    repairOrderQuoteRecommendation: RepairOrderQuoteRecommendation | null;
    name: string;
    chargeAmount: number;
    deleted: boolean;
    type: string | null;
    minCharge: string | null;
    maxCharge: string | null;
    amount: number;
};

export type RepairOrderReviewInteractions = {
    id: number;
    repairOrderReview: RepairOrderReview;
    user: User | null;
    customer: Customer | null;
    type: string | null;
};

export type RepairOrderRewards = {
    id: number;
    repairOrder: RepairOrder | null;
    customer: Customer;
    date: Date;
    price: string;
};

export type RepairOrderVideo = {
    id: number;
    path: string;
    status: string;
    interactions: RepairOrderInteraction[];
    duration: number | null;
};

export type RepairOrderUvEyeUrl = {
    id: number;
    date: Date;
    expirationDate: Date | null;
    inspectionDate: Date | null;
    url: string;
    repairOrder: RepairOrder;
};

export type Rewards = {
    id: number;
    customer: Customer;
    repairOrder: RepairOrder;
    points: number;
    spent: boolean;
    date: Date;
    rewards: number;
};

export type ServicePhase = {
    id: number;
    name: string;
    color: string;
    textColor: string | null;
    sortOrder: number;
    special: boolean;
    sendSms: boolean;
    smsMessage: string;
};

export type ServiceOffHour = {
    id: number;
    description: string;
    startDate: Date;
    endDate: Date;
};

export type ServiceQuickSms = {
    id: number;
    user: User | null;
    message: string;
};

export type Sms = {
    id: number;
    user: User | null;
    customer: Customer | null;
    phone: string;
    message: string;
    incoming: boolean;
    isRead: boolean;
    date: Date;
    sid: string | null;
    status: string | null;
    mmsContent: string | null;
    type: string | null;
    automated: boolean;
    campaign: Campaign | null;
    readBy: User | null;
    readDate: Date | null;
    incomingToPhone: string | null;
};

export type ScheduledMaintenance = {
    name: string;
    frequencyType: string;
    intervalMiles: number;
    intervalMonths: number;
    estimates: Estimate[];
};

export type ServiceExpress = {
    id: number;
    name: string;
    color: string;
    textColor: string;
    sortOrder: number;
    special: boolean;
    sendSms: boolean;
    smsMessage: string;
    serviceExpressRepairOrders: any;
};

export type ServiceLaneCapacity = {
    id: number;
    type: string;
    time: string;
    sundayHours: string;
    mondayHours: string;
    tuesdayHours: string;
    wednesdayHours: string;
    thursdayHours: string;
    fridayHours: string;
    saturdayHours: string;
};

export type Setting = {
    key: string;
    value: string | null;
};

export type SettingsEnvironment = {
    dmsEnv: boolean;
    dealerbuiltLocationId: boolean;
    nmiPassword: boolean;
    timezone: boolean;
    sparrowDealerId: boolean;
    automateEndpointId: boolean;
    cdkDealerId: boolean;
    cdkSubscriptionId: boolean;
    dealertrackCompany: boolean;
    dealertrackEnterprise: boolean;
    dealerbuiltSourceId: boolean;
    nmiUsername: boolean;
    heartlandApiKey: boolean;
    finmktUsername: boolean;
    finmktPassword: boolean;
    enableCommandLogger: boolean;
    supportEmail: boolean;
    sparrowApiUrl: boolean;
    uveyeApiUrl: boolean;
    uveyeApiKey: boolean;
    autosoftDealerCode: boolean;
};

export type SmsThread = {
    id: number;
    name: string;
    phone: string;
    repairOrderID: number;
    repairOrderNumber: string;
    newestRoAdvisorId: number;
    newestRoClosedDate: string;
    newestTextId: number;
    message: string;
    date: string;
    newestOutgoingAdvisorId: number;
    newestOutgoingDate: string;
    totalResults: number;
    unread: number;
    priority: number;
};

export type TransportationType = {
    id: number;
    title: string;
    description: string | null;
    internal: boolean | null;
};

export type UnreadMessageCounts = {
    internal: number;
    sms: number;
    service: number;
    sales: number;
};

export type User = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: UserRoles;
    certification: string | null;
    experience: string | null;
    securityQuestion: string | null;
    lastLogin: Date | null;
    active: boolean;
    processRefund: boolean;
    shareRepairOrders: boolean;
    dmsId: string | null;
    externalAuthentication: boolean;
    allowBdc: boolean | null;
};

export type Upgrade = {
    id: number;
    dateCreated: Date | null;
    dateSent: Date | null;
    dateCompleted: Date | null;
    valueMinimum: number | null;
    valueMaximum: number | null;
    trim: string | null;
    status: string;
    vehicleCondition: string | null;
    vehicle_id: number | null;
};

// TODO use this instead of UserRoles constant
export enum UserRoles {
    ADMIN = 'ROLE_ADMIN',
    BDC_Manager = 'ROLE_BDC_MANAGER',
    BDC_AGENTS = 'ROLE_BDC',
    GLOBAL_PAY = 'ROLE_GLOBAL_PAY',
    PARTS_ADVISOR = 'ROLE_PARTS_ADVISOR',
    SALES_AGENT = 'ROLE_SALES_AGENT',
    SALES_MANAGER = 'ROLE_SALES_MANAGER',
    SERVICE_ADVISOR = 'ROLE_SERVICE_ADVISOR',
    SERVICE_MANAGER = 'ROLE_SERVICE_MANAGER',
    TECHNICIAN = 'ROLE_TECHNICIAN',
}

export const PaymentValidationWarningStates = ['Paid', 'Refunded', 'Confirmed'];

export type Media = {
    dateUploaded: string;
    id: number;
    media: string;
    note: string | null;
    type: string;
};

export const RepairOrderMediaType = {
    image: 'IMAGE',
    video: 'VIDEO',
};
