import { UserRoles } from 'types';

// Tech app button statuses
export const buttonStatuses = {
    mpi: {
        'Not Started': 'danger',
        Sent: 'success',
        Viewed: 'success',
        Complete: 'success',
    },
    quote: {
        'Not Started': 'danger',
        'Advisor In Progress': 'success',
        'Technician In Progress': 'danger',
        'Parts In Progress': 'success',
        Sent: 'success',
        Viewed: 'success',
        Complete: 'success',
        Confirmed: 'success',
    },
    video: {
        'Not Started': 'danger',
        Uploaded: 'success',
        Sent: 'success',
        Viewed: 'success',
    },
};

Object.freeze(buttonStatuses);

// Dashboard button color and text map
export const dashboardStatuses = {
    mpi: {
        'Not Started': {
            advisor: { color: 'white', icon: 'list' },
            parts: { color: 'white', icon: 'list' },
            technician: { color: 'danger', icon: 'list' },
        },
        Sent: {
            advisor: {
                color: 'success',
                customText: 'Complete',
                icon: 'list',
            },
            parts: { color: 'success', icon: 'list' },
            technician: { color: 'success', icon: 'list' },
        },
        Viewed: {
            advisor: { color: 'success', icon: 'list' },
            parts: { color: 'success', icon: 'list' },
            technician: { color: 'success', icon: 'list' },
        },
        Complete: {
            advisor: { color: 'success', icon: 'list' },
            parts: { color: 'success', icon: 'list' },
            technician: { color: 'success', icon: 'list' },
        },
    },
    video: {
        'Not Started': {
            advisor: { color: 'white', icon: 'video' },
            parts: { color: 'white', icon: 'video' },
            technician: { color: 'danger', icon: 'video' },
        },
        Uploaded: {
            advisor: { color: 'success', icon: 'video' },
            parts: { color: 'success', icon: 'video' },
            technician: { color: 'success', icon: 'video' },
        },
        Sent: {
            advisor: { color: 'success', icon: 'video' },
            parts: { color: 'success', icon: 'video' },
            technician: { color: 'success', icon: 'video' },
        },
        Viewed: {
            advisor: { color: 'success', icon: 'video', fill: 'success' },
            parts: { color: 'success', icon: 'video', fill: 'success' },
            technician: { color: 'success', icon: 'video', fill: 'success' },
        },
    },
    iPay: {
        Confirmed: {
            advisor: { color: 'success', icon: 'dollar' },
        },
        Created: {
            advisor: { color: 'danger', icon: 'dollar' },
        },
        'Not Started': {
            advisor: { color: 'white', icon: 'dollar' },
        },
        Paid: {
            advisor: { color: 'danger', icon: 'dollar' },
        },
        Refunded: {
            advisor: { color: 'danger', icon: 'dollar' },
        },
        Sent: {
            advisor: { color: 'success', icon: 'dollar' },
        },
        Viewed: {
            advisor: { color: 'success', icon: 'dollar' },
        },
    },
    quote: {
        'Not Started': {
            technician: { color: 'danger', icon: 'file' },
            parts: { color: 'white', icon: 'file' },
            advisor: { color: 'white', icon: 'file' },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_ADVISOR,
                UserRoles.SERVICE_MANAGER,
                UserRoles.TECHNICIAN,
                UserRoles.PARTS_ADVISOR,
            ],
        },
        'Advisor In Progress': {
            technician: { color: 'success', icon: 'file' },
            parts: { color: 'success', icon: 'file' },
            advisor: { color: 'danger', icon: 'file' },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_ADVISOR,
                UserRoles.SERVICE_MANAGER,
                UserRoles.PARTS_ADVISOR,
                UserRoles.TECHNICIAN,
            ],
        },
        'Technician In Progress': {
            technician: { color: 'danger', icon: 'file' },
            parts: { color: 'white', icon: 'file' },
            advisor: { color: 'white', icon: 'file' },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_MANAGER,
                UserRoles.TECHNICIAN,
                UserRoles.PARTS_ADVISOR,
            ],
        },
        'Parts In Progress': {
            technician: { color: 'success', icon: 'file' },
            parts: { color: 'danger', icon: 'file' },
            advisor: { color: 'white', icon: 'file' },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.PARTS_ADVISOR,
                UserRoles.TECHNICIAN,
                UserRoles.SERVICE_MANAGER,
            ],
        },
        Sent: {
            technician: { color: 'success', icon: 'file' },
            parts: { color: 'success', icon: 'file' },
            advisor: { color: 'success', icon: 'file' },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_ADVISOR,
                UserRoles.SERVICE_MANAGER,
                UserRoles.PARTS_ADVISOR,
                UserRoles.TECHNICIAN,
            ],
        },
        Viewed: {
            technician: { color: 'success', icon: 'file', fill: 'success' },
            parts: { color: 'success', icon: 'file', fill: 'success' },
            advisor: { color: 'success', icon: 'file', fill: 'success' },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_ADVISOR,
                UserRoles.SERVICE_MANAGER,
                UserRoles.PARTS_ADVISOR,
                UserRoles.TECHNICIAN,
            ],
        },
        Completed: {
            technician: {
                color: 'warning',
                customText: 'Replied',
                icon: 'file',
            },
            parts: {
                color: 'warning',
                customText: 'Replied',
                icon: 'file',
            },
            advisor: {
                color: 'warning',
                customText: 'Replied',
                icon: 'file',
            },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_ADVISOR,
                UserRoles.SERVICE_MANAGER,
                UserRoles.PARTS_ADVISOR,
                UserRoles.TECHNICIAN,
            ],
        },
        Confirmed: {
            technician: {
                color: 'success',
                customText: 'Complete',
                icon: 'file',
            },
            parts: {
                color: 'success',
                customText: 'Complete',
                icon: 'file',
            },
            advisor: {
                color: 'success',
                customText: 'Complete',
                icon: 'file',
            },
            permissions: [
                UserRoles.ADMIN,
                UserRoles.SERVICE_ADVISOR,
                UserRoles.SERVICE_MANAGER,
                UserRoles.PARTS_ADVISOR,
                UserRoles.TECHNICIAN,
            ],
        },
    },
    pickList: {
        Pick: {
            technician: { color: 'danger' },
            parts: { color: 'danger' },
        },
        Picked: {
            technician: { color: 'warning' },
            parts: { color: 'warning' },
        },
        'Picked Up': {
            technician: { color: 'success' },
            parts: { color: 'success' },
        },
    },
};
Object.freeze(dashboardStatuses);
