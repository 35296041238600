import { combineReducers } from 'redux';
import customizer from './customizer';
import auth from './auth';
import app, { AppInitialState } from './app';
import navbar from './navbar/Index';
import { CustomizerInitialState } from './customizer/customizer';

const rootReducer = combineReducers({
    customizer,
    auth,
    navbar,
    app,
});

export interface RootState {
    auth: {
      login: any;
    };
    app: AppInitialState;
    customizer: {
      customizer: CustomizerInitialState;
    };
    navbar: any;
  }

export default rootReducer;
