import { Preferences } from '@capacitor/preferences';

export const getStorageValue = async (storageKey) => {
    const { value } = await Preferences.get({ key: storageKey });

    return value;
};

export const removeStorage = async (storageKey) => {
    return await Preferences.remove({ key: storageKey });
};

export const setStorageObj = async (key, value) => {
    return await Preferences.set({
        key,
        value,
    });
};

export const getInvalidETagMessage = (message) => {
    return message.replace('ERROR_ETAG. ', '');
};

export const isETagInvalidError = (error) => {
    return !!error.response?.data.message.includes('ERROR_ETAG.');
};

export const handleETagError = (error) => (dispatch) => {
    if (isETagInvalidError(error)) {
        const message = getInvalidETagMessage(error.response?.data.message);
        if (message) {
            dispatch({ type: 'SET_INVALID_ETAG_MESSAGE', data: message });
        }
    }
};
