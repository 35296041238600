import axios from 'axios';
import qs from 'qs';
import { Network } from '@capacitor/network';
import { store } from '../redux/storeConfig/store';
import { Endpoints } from '../utility/constants';
import { getStorageValue } from '../utility/helpers';

const instance = axios.create();
instance.interceptors.request.use(
    async (config) => {
        const networkStatus = await Network.getStatus();
        if (!networkStatus.connected) {
            throw new Error(
                'Unable to connect. Please check your internet connection.'
            );
        }

        const subdomain = await getStorageValue(
            'iservice_technician_subdomain'
        );
        const techToken = await getStorageValue(
            'iservice_technician_tech_token'
        );
        const appToken = await getStorageValue('iservice_technician_app_token');

        const { isTechLoginExpired } = store.getState().app;
        const token =
            config.url === Endpoints.users && !techToken
                ? appToken
                : !isTechLoginExpired && !!techToken
                  ? techToken
                  : '';

        config.baseURL = `https://${subdomain}.iserviceauto.com/api`;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (config.method === 'post') {
            if (!config.data) throw new Error('Please provide a request body');
            config.transformRequest = (data) => {
                const formData = new FormData();

                for (const key in data) {
                    formData.append(key, data[key]);
                }

                return formData;
            };
        }

        if (config.method === 'put') {
            config.transformRequest = (data) => qs.stringify(data);
        }

        if (config.method === 'patch') {
            config.transformRequest = (data) => qs.stringify(data);
        }

        if (config.method === 'get') {
            config.paramsSerializer = (params) => qs.stringify(params);
        }

        if (config.method === 'delete') {
            const endsWithNumber = /.*[0-9]$/;
            if (!endsWithNumber.test(config.url))
                throw new Error(
                    'Please provide id for the entity you wish to delete'
                );
        }

        return config;
    },
    (response) => response
);

export default instance;
