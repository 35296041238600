import { MpiTemplate, PriceMatrix, RepairOrder, User } from 'types';

export interface AppInitialState {
    appUser: User | null;
    checkInList: any[];
    currentRepairOrder: RepairOrder | Record<string, never>;
    isLoading: boolean;
    isTechLoginExpired: boolean;
    laborRates: any | null;
    mpiItemId: number | null; // Makes the clicked MPI item accessible to quoting
    mpiTemplate: MpiTemplate | null;
    mpiTemplates: MpiTemplate[];
    priceMatrix: PriceMatrix[];
    progressValue: number;
    repairOrders: RepairOrder[];
    settings: any;
    techniciansList: User[];
    techUser: User | null;
    invalidQuoteETagMessage: string | null;
}

const initialState: AppInitialState = {
    appUser: null,
    checkInList: [],
    currentRepairOrder: {},
    isLoading: false,
    isTechLoginExpired: false,
    laborRates: null,
    mpiItemId: null,
    mpiTemplate: null,
    mpiTemplates: [],
    priceMatrix: [],
    progressValue: 0,
    repairOrders: [],
    settings: {}, // this was an empty array, changed to empty object - should default null, but I don't want to break the app right now
    techniciansList: [],
    techUser: null,
    invalidQuoteETagMessage: 'TEST',
};

export const appReducer = (state = initialState, action) => {
    const { data, error } = action;
    switch (action.type) {
        case 'CLEAR_CURRENT_RO':
            return { ...state, currentRepairOrder: {} };
        case 'GET_MPI_TEMPLATES':
            return {
                ...state,
                isLoading: true,
                mpiTemplate: null,
                mpiTemplates: [],
            };
        case 'GET_MPI_TEMPLATE_BY_ID':
            return {
                ...state,
                isLoading: true,
                mpiTemplate: null,
            };
        case 'HANDLE_ERROR': {
            return { ...state, isLoading: false, error };
        }
        case 'LOADING': {
            return { ...state, isLoading: true };
        }
        case 'LOADING_END': {
            return { ...state, isLoading: false };
        }
        case 'APP_LOGGED_IN': {
            return { ...state, isLoading: false, appUser: data };
        }
        case 'TECH_LOGGED_IN': {
            return {
                ...state,
                isTechLoginExpired: false,
                techUser: data,
            };
        }
        case 'TECH_LOGIN_EXPIRED':
            return { ...state, isTechLoginExpired: true };
        case 'TECH_LOGGED_OUT': {
            return {
                ...state,
                isTechLoginExpired: false,
            };
        }
        case 'MPI_RESULTS_SUBMITTED':
            return {
                ...state,
                isLoading: false,
                mpiTemplate: null,
                mpiTemplates: [],
            };
        case 'RECEIVE_MPI_TEMPLATES':
            return {
                ...state,
                isLoading: false,
                mpiTemplates: data,
            };
        case 'RECEIVE_MPI_TEMPLATE_BY_ID':
            return {
                ...state,
                isLoading: false,
                mpiTemplate: data,
            };
        case 'RECEIVE_RO_LIST': {
            return { ...state, isLoading: false, repairOrders: data };
        }
        case 'RECEIVE_MORE_RO_LIST': {
            return {
                ...state,
                isLoading: false,
                repairOrders: Array.isArray(data)
                    ? [...state.repairOrders, ...data]
                    : state.repairOrders,
            };
        }
        case 'RECEIVE_TECH_LIST': {
            return { ...state, isLoading: false, techniciansList: data };
        }
        case 'RECEIVE_SETTINGS':
            return { ...state, settings: data };
        case 'RECEIVE_LABOR_RATES':
            return { ...state, laborRates: data };
        case 'RECEIVE_PRICE_MATRIX':
            return { ...state, priceMatrix: data };
        case 'RECOMMENDATIONS_SUBMITTED':
            return {
                ...state,
                isLoading: false,
            };
        case 'SET_CHECK_IN_LIST':
            return { ...state, isLoading: false, checkInList: data };
        case 'SET_CURRENT_RO':
            return {
                ...state,
                currentRepairOrder: data,
                invalidQuoteETagMessage: null,
            };
        case 'SET_STORE': {
            return { ...state, ...data };
        }
        case 'SEND_VIDEO': {
            return { ...state, isLoading: false };
        }
        case 'UPDATE_PROGRESS': {
            return { ...state, progressValue: data };
        }
        case 'SET_INVALID_ETAG_MESSAGE': {
            return { ...state, invalidQuoteETagMessage: data };
        }
        default: {
            return state;
        }
    }
};

export default appReducer;
