import React from 'react';
import { connect } from 'react-redux';
import { Navbar } from 'reactstrap';
import classnames from 'classnames';
import NavbarBookmarks from './NavbarBookmarks';
import logo from '../../../assets/img/logo/iServiceLogo.png';
import { RefreshCw } from 'react-feather';
import { refresh } from '../../../redux/actions/app';
import { Capacitor } from '@capacitor/core';
//import NavbarUser from "./NavbarUser";

const ThemeNavbar = (props) => {
    const colorsArr = [
        'primary',
        'danger',
        'success',
        'info',
        'warning',
        'dark',
    ];
    const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
    return (
        <React.Fragment>
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar
                className={classnames(
                    'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
                    {
                        'navbar-light':
                            props.navbarColor === 'default' ||
                            !colorsArr.includes(props.navbarColor),
                        'navbar-dark': colorsArr.includes(props.navbarColor),
                        'bg-primary':
                            props.navbarColor === 'primary' &&
                            props.navbarType !== 'static',
                        'bg-danger':
                            props.navbarColor === 'danger' &&
                            props.navbarType !== 'static',
                        'bg-success':
                            props.navbarColor === 'success' &&
                            props.navbarType !== 'static',
                        'bg-info':
                            props.navbarColor === 'info' &&
                            props.navbarType !== 'static',
                        'bg-warning':
                            props.navbarColor === 'warning' &&
                            props.navbarType !== 'static',
                        'bg-dark':
                            props.navbarColor === 'dark' &&
                            props.navbarType !== 'static',
                        'd-none':
                            props.navbarType === 'hidden' && !props.horizontal,
                        'floating-nav':
                            (props.navbarType === 'floating' &&
                                !props.horizontal) ||
                            (!navbarTypes.includes(props.navbarType) &&
                                !props.horizontal),
                        'navbar-static-top':
                            props.navbarType === 'static' && !props.horizontal,
                        'fixed-top':
                            props.navbarType === 'sticky' || props.horizontal,
                        scrolling: props.horizontal && props.scrolling,
                    }
                )}
            >
                <div
                    className={
                        Capacitor.getPlatform() === 'ios'
                            ? 'mt-3 navbar-wrapper'
                            : 'navbar-wrapper'
                    }
                >
                    <div className="navbar-container content">
                        <div
                            className="navbar-collapse d-flex justify-content-between align-items-center"
                            id="navbar-mobile"
                        >
                            <div className="bookmark-wrapper">
                                <NavbarBookmarks
                                    sidebarVisibility={props.sidebarVisibility}
                                    handleAppOverlay={props.handleAppOverlay}
                                />
                            </div>
                            {props.horizontal ? (
                                <div className="logo d-flex align-items-center">
                                    <h2 className="text-primary brand-text mb-0">
                                        iService
                                    </h2>
                                </div>
                            ) : null}
                            {/* <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="John Doe"
                userImg={userImg}
              /> */}
                            <div>
                                <img
                                    className="img-thumbnail navbar-img"
                                    src={logo}
                                    alt="logo"
                                    style={{ maxHeight: '50px', width: 'auto' }}
                                />
                            </div>
                            <RefreshCw
                                className="font-weight-bold text-white mr-2"
                                onClick={props.refresh}
                                size={30}
                            />
                        </div>
                    </div>
                </div>
            </Navbar>
        </React.Fragment>
    );
};

export default connect(null, { refresh })(ThemeNavbar);
