import clientApi from '../../../api/clientApi';
import { Endpoints } from '../../../utility/constants';
import { showToast } from '../../../toast';
import { history } from '../../../history';
import { removeStorage, setStorageObj } from '../../../utility/helpers';
import { handleError, getRoList } from '../app';

const subdomainWhitelist = ['docker'];

export const changeRole = (role) => {
    return (dispatch) => dispatch({ type: 'CHANGE_ROLE', userRole: role });
};

export const logoutApp = () => async (dispatch) => {
    removeStorage('iservice_technician_app_token');
    removeStorage('iservice_technician_tech_token');
    removeStorage('iservice_technician_tech_username');

    dispatch({ type: 'LOGOUT_APP' });
};

export const logoutTech = () => async (dispatch) => {
    removeStorage('iservice_technician_tech_token');
    removeStorage('iservice_technician_tech_username');
    dispatch({ type: 'TECH_LOGGED_OUT' });
    history.push('/');
    showToast('Technician logged out');
};

export const loginApp = (username, password, subdomain) => async (dispatch) => {
    dispatch({ type: 'LOADING' });
    const credentials = { username, password };

    setStorageObj(
        'iservice_technician_subdomain',
        addSubdomainPrefix(subdomain)
    );

    try {
        const response = await clientApi.post(
            Endpoints.authentication,
            credentials
        );

        removeStorage('iservice_technician_tech_token');
        removeStorage('iservice_technician_tech_username');

        setStorageObj('iservice_technician_app_token', response.data.token);

        dispatch({ type: 'APP_LOGGED_IN', data: response.data.user });
    } catch (error) {
        let message = null;

        if (error?.message === 'Network Error') {
            message = 'Something went wrong! Please try again.';
        } else if (error?.response?.status === 403) {
            message = 'Incorrect username or password.';
        }

        return dispatch(handleError(error, message));
    }
};

export const loginTech = (tech, pin) => async (dispatch) => {
    dispatch({ type: 'LOADING' });
    const credentials = { username: tech.email, pin };
    try {
        const response = await clientApi.post(
            Endpoints.authentication,
            credentials
        );

        tech.techToken = response.data.token;
        setStorageObj('iservice_technician_tech_token', response.data.token);
        setStorageObj('iservice_technician_tech_username', tech.email);

        dispatch({ type: 'TECH_LOGGED_IN', data: response.data.user });
        dispatch(getRoList({ pageLimit: 20 }));
        return true;
    } catch (error) {
        if (error?.response?.status === 403) {
            return dispatch(handleError(error, 'Incorrect PIN.'));
        }

        return dispatch(handleError(error));
    } finally {
        dispatch({ type: 'LOADING_END' });
    }
};

const addSubdomainPrefix = (subdomain) => {
    const prefix = 'api.';
    return subdomain.includes(prefix) || subdomainWhitelist.includes(subdomain)
        ? subdomain
        : `${prefix}${subdomain}`;
};
