import clientApi from '../../../api/clientApi';
import { Endpoints } from '../../../utility/constants';
import { showToast } from '../../../toast';
import { getRoById, handleError } from '.';

export const getMpiTemplateById = (templateId) => async (dispatch) => {
    dispatch({ type: 'GET_MPI_TEMPLATE_BY_ID' });

    try {
        const response = await clientApi.get(
            `${Endpoints.mpiTemplates}/${templateId}`
        );
        dispatch({ type: 'RECEIVE_MPI_TEMPLATE_BY_ID', data: response.data });
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const getMpiTemplates = () => async (dispatch) => {
    dispatch({ type: 'GET_MPI_TEMPLATES' });

    try {
        const response = await clientApi.get(Endpoints.mpiTemplates);
        dispatch({
            type: 'RECEIVE_MPI_TEMPLATES',
            data: response.data && response.data.results,
        });
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const submitMpiResults = (mpiResults, repairOrderId) => async (
    dispatch
) => {
    dispatch({ type: 'LOADING' });
    const data = {
        results: JSON.stringify(mpiResults),
        repairOrderID: repairOrderId,
    };

    try {
        const response = await clientApi.post(Endpoints.mpi, data);
        if (response.status !== 200) {
            throw new Error(
                'Failed to submit MPI results. Please try again or contact support.'
            );
        }
        showToast('Submitted Successfully!');
        dispatch({ type: 'MPI_RESULTS_SUBMITTED' });
        dispatch(getRoById(repairOrderId));
        return true;
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};