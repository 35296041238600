import { UserRoles } from 'types';

let baseURL: string = process.env.REACT_APP_DEVELOPMENT_API_URL!;

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    baseURL = process.env.REACT_APP_DEVELOPMENT_API_URL!;
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    baseURL = process.env.REACT_APP_STAGING_API_URL!;
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    baseURL = process.env.REACT_APP_PRODUCTION_API_URL!;
}

export { baseURL };

export const Endpoints = {
    advisorRecommendationReport: '/reporting/advisor-recommendation',
    advisorReport: '/reporting/user-metrics',
    advisorUsageReport: '/reporting/advisors-usage',
    advisorUsageReportForRVIndustry: '/reporting/user-metrics',
    appointment: '/appointment',
    appointmentCodes: '/appointment-code',
    appointmentCodeGroup: '/appointment-code-group',
    appointmentCodeopCode: '/appointment-code-operation-code',
    appointmentGroupSortOrder: '/appointment-code-group/sort-order',
    appointmentCodeSortOrder: '/appointment-code/sort-order',
    appointmentAvailability: '/appointment-availability',
    authentication: '/authentication/authenticate',
    bdcExpress: '/bdc-express',
    bdcPerformanceReport: '/reporting/bdc-performance',
    bdcPhase: '/bdc-phase',
    bdcPhaseReAssign: '/bdc-phase/re-assign',
    bdcPhaseSortOrder: '/bdc-phase/sort-order',
    bdcPhaseTrendReport: '/reporting/bdc-phase-trend',
    bdcPhaseUserTrendReport: '/reporting/bdc-phase-user-trend',
    bdcQuickSMS: '/bdc-quick-sms',
    bdcQuickSMSGroup: '/bdc-quick-sms-group',
    bdcPhaseCycleTime: '/reporting/bdc-phase-cycle-time',
    bdcUsageReport: '/reporting/user-metrics',
    campaign: '/campaign',
    campaignTarget: '/campaign/target',
    campaignPerformance: '/reporting/campaign-performance',
    checkIn: '/checkin',
    command: '/command',
    confirmQuote: '/repair-order-quote/confirm',
    coupons: '/coupons',
    customers: '/customer',
    customerEngagementReport: '/reporting/customer-engagements',
    customerEngagementReportForRVIndustry: '/reporting/user-metrics',
    customerNote: '/customer-note',
    declinedServiceReport: '/reporting/repair-order-quote',
    demo: '/demo',
    environment: '/settings/environment',
    followUpReporting: '/repair-order',
    followUpTargets: 'follow-up/targets',
    internalMessage: '/internal-message',
    'Internal Messages': '/internal-message',
    iPayReport: '/repair-order-payment',
    kbbMake: '/kbb/kbb-make',
    kbbModel: '/kbb/kbb-model',
    kbbYear: '/kbb/kbb-year',
    laborRateDmsCodes: '/labor-rate/dms-codes',
    laborRates: '/labor-rate',
    laborRateMatrix: '/labor-rate-matrix',
    lightyearFinanceInvoiceById: '/dms/deal-by-number',
    lightyearPartsInvoiceById: '/dms/part-invoice-by-number',
    motorDotComBaseVehicleDetails: 'motor/vehicle/base-vehicle-details',
    motorDotComEngines: 'motor/vehicle/engines',
    motorDotComLaborEstimates: 'motor/estimated-work-times',
    motorDotComLaborGroups: 'motor/estimated-work-times/groups',
    motorDotComLaborSubGroups: 'motor/estimated-work-times/sub-groups',
    motorDotComLaborSystems: 'motor/estimated-work-times/systems',
    motorDotComMakes: 'motor/vehicle/makes',
    motorDotComModels: 'motor/vehicle/models',
    motorDotComParts: 'motor/parts',
    motorDotComPartsGroups: 'motor/parts/groups',
    motorDotComPartsSubGroups: 'motor/parts/sub-groups',
    motorDotComPartsSystems: 'motor/parts/systems',
    motorDotComVinSearch: 'motor/vehicle/by-vin',
    motorDotComYears: 'motor/vehicle/years',
    mpi: '/repair-order-mpi',
    mPIGroup: 'mpi-group',
    mPIItem: 'mpi-item',
    mpiReport: '/reporting/mpi',
    mpiTemplates: 'mpi-template',
    mpiItemOpCode: '/mpi-item-operation-code',
    myReviewAdvisorReport: 'myReview/advisor-reports',
    myLoanReporting: '/reporting/my-loan',
    myLoanDashboard: '/my-loan/',
    opCodes: 'operation-code',
    overviewReport: '/reporting/trend',
    parts: '/parts',
    partsPrice: '/parts/price',
    partsExpress: '/parts-express',
    partsPickList: 'repair-order-pick-list-part',
    partsReporting: '/parts-reporting',
    paymentsNonRO: '/payment',
    paymentsRO: '/repair-order-payment',
    paymentTerminal: '/payment-terminal',
    paymentTerminalPay: '/payment/terminal-pay',
    paymentTerminalCancel: '/payment/terminal-cancel',
    paymentTerminalRefund: '/payment/terminal-refund',
    paymentROTerminalPay: '/repair-order-payment/terminal-pay',
    paymentROTerminalCancel: '/repair-order-payment/terminal-cancel',
    paymentROTerminalRefund: '/repair-order-payment/terminal-refund',
    paymentMethods: '/dms/payment-methods',
    pointsAward: '/points/award',
    pointsRedeem: '/points/redeem',
    priceMatrix: '/price-matrix',
    partMatrixCollection: 'part-matrix-collection',
    partMatrices: 'part-matrix-collection',
    partMatrixValue: 'part-matrix-value',
    partMatrix: 'part-matrix',
    quote: '/repair-order-quote',
    quoteComplete: '/repair-order-quote/complete',
    quoteInProgress: '/repair-order/quote-in-progress',
    quoteRecommendation: '/repair-order-quote-recommendation',
    quoteRecommendationSortOrder:
        '/repair-order-quote-recommendation/sort-order',
    quotePullRecommendations: '/repair-order-quote/pull-recommendations',
    quotePushRecommendations: '/repair-order-quote/push-recommendations',
    quoteRecommendationParts: '/repair-order-quote-recommendation-part',
    quoteDiscounts: '/quote-discount',
    quoteMiscCharges: '/quote-misc-charge',
    recommendationReport: '/reporting/recommendation',
    reconditioningExpress: '/reconditioning-express',
    reconditioningPhase: '/reconditioning-phase',
    reconditioningPhaseCycleTime: '/reporting/reconditioning-phase-cycle-time',
    reconditioningPhaseSortOrder: '/reconditioning-phase/sort-order',
    reconditioningReAssign: '/reconditioning-phase/re-assign',
    repairOrderByDmsId: '/dms/repair-order-by-number',
    repairOrderCustomer: '/repair-order-customer',
    repairOrderMedia: '/repair-order-media',
    repairOrderInteractions: '/repair-order-interactions',
    repairOrderReconditioingPhase:
        '/repair-order-reconditioning-phase-interaction',
    repairOrderServicePhase: 'repair-order-service-phase-interaction',
    repairOrderQuote: '/repair-order-quote',
    repairOrderQuoteDiscounts: '/repair-order-quote-discount',
    repairOrderQuoteMiscCharges: '/repair-order-quote-misc-charge',
    reportingCustomer: '/reporting/customer',
    resendVideo: '/repair-order-video/send',
    resendWaiver: '/repair-order-waiver/re-send',
    rewards: '/rewards',
    rewardsTier: '/rewards-tier',
    roNotes: '/repair-order-note',
    roReport: 'myReview/ro-reports',
    roReporting: '/repair-order',
    ros: '/repair-order',
    roReopen: 'repair-order/re-open',
    roVideo: '/repair-order-video',
    scheduledMaintenance: 'scheduled-maintenance',
    security: '/security',
    sendQuote: '/repair-order-quote/send',
    serviceExpress: '/service-express',
    serviceLaneCapacity: '/service-lane-capacity',
    serviceOffHour: '/service-off-hour',
    'Service SMS': '/service-sms',
    serviceQuickSMS: '/service-quick-sms',
    SMS: '/sms',
    servicePhase: '/service-phase',
    servicePhaseCycleTime: '/reporting/service-phase-cycle-time',
    servicePhaseReAssign: '/service-phase/re-assign',
    servicePhaseSortOrder: '/service-phase/sort-order',
    servicePhaseTrendReport: '/reporting/service-phase-trend',
    servicePhaseUserTrendReport: '/reporting/service-phase-user-trend',
    serviceSMSMarkRead: '/service-sms/mark-read',
    settings: '/settings',
    smsMarkRead: '/sms/mark-read',
    support: '/support',
    syncQuote: '/repair-order-recommendations-sync',
    technicianRecommendationReport: '/reporting/technician-recommendation',
    technicianReport: '/reporting/user-metrics',
    technicianUsageReport: '/reporting/user-metrics',
    transferQuote: '/repair-order-quote/in-progress',
    transportationType: '/transportation-type',
    UVEye: '/uv-eye',
    unreadMessageCounts: '/message/unread',
    upgrade: '/upgrade',
    upgradeReporting: '/upgrade-reporting',
    [UserRoles.PARTS_ADVISOR]: 'users',
    [UserRoles.BDC_AGENTS]: 'users',
    [UserRoles.SALES_AGENT]: 'users',
    [UserRoles.SALES_MANAGER]: 'users',
    [UserRoles.SERVICE_ADVISOR]: 'users',
    [UserRoles.SERVICE_MANAGER]: 'users',
    [UserRoles.TECHNICIAN]: 'users',
    [UserRoles.BDC_Manager]: 'users',
    users: '/users',
    validateMobilePhone: '/phone-lookup',
    vehicleMakes: '/vehicle/makes',
    vehicleModels: '/vehicle/models',
    vehicleReconditioningPhaseTrendReport:
        '/reporting/reconditioning-phase-trend',
    vehicleReconditioningUserTrendReport:
        '/reporting/reconditioning-phase-user-trend',
    vehicleYears: '/vehicle/years',
} as const;
