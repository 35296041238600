import clientApi from '../../../api/clientApi';
import { Endpoints } from '../../../utility/constants';
import { handleError } from '.';

const CONTACT_MESSAGE = ' Please try again or contact support.';

export const getRoById = (repairOrderId) => async (dispatch) => {
    dispatch({ type: 'LOADING' });
    try {
        const laborRatesResponse = await clientApi.get(
            `${Endpoints.laborRates}`
        );
        const roResponse = await clientApi.get(
            `${Endpoints.ros}/${repairOrderId}`
        );
        const settingsResponse = await clientApi.get(`${Endpoints.settings}`);
        const settings = settingsResponse.data.reduce((acc, setting) => {
            acc[setting.key] = setting.value;
            return acc;
        }, {});

        // Add default labor rate to settings
        if (settings.quote === '1') {
            const defaultLaborRateId = parseInt(settings.defaultLaborRateId);
            if (!defaultLaborRateId)
                throw new Error('Please set a default Pay Type');

            const defaultLaborRateResponse = await clientApi.get(
                `${Endpoints.laborRates}/${defaultLaborRateId}`
            );
            if (!defaultLaborRateResponse.data) {
                throw new Error(
                    'Something went wrong finding the default Pay Type. Please reopen the app or contact Support.'
                );
            }

            settings.defaultLaborRate = defaultLaborRateResponse.data;
        }

        if (
            settings.pricingUseMatrix === 'true' ||
            settings.pricingUseMatrix === '1' ||
            settings.pricingUseMatrix === true
        ) {
            const matrixResponse = await clientApi.get(Endpoints.priceMatrix);

            dispatch({
                type: 'RECEIVE_PRICE_MATRIX',
                data: matrixResponse.data,
            });
        }

        dispatch({
            type: 'RECEIVE_LABOR_RATES',
            data: laborRatesResponse.data.results,
        });
        dispatch({
            type: 'RECEIVE_SETTINGS',
            data: settings,
        });
        dispatch({
            type: 'SET_CURRENT_RO',
            data: roResponse.data,
        });
        dispatch({ type: 'LOADING_END' });
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getRoList = (params) => async (dispatch) => {
    dispatch({ type: 'LOADING' });
    const config = {
        params: {
            open: true,
            sortField: 'dateCreated',
            sortDirection: 'DESC',
            page: 1,
            pageLimit: 50,
            ...params,
        },
    };
    let response;
    try {
        response = await clientApi.get(Endpoints.ros, config);

        if (config.params.page === 1) {
            dispatch(receiveRoList(response.data.results));
        } else {
            dispatch(recieveMoreRoList(response.data.results));
        }
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        return response;
    }
};

export const receiveRoList = (roList) => {
    return (dispatch) => {
        dispatch({ type: 'RECEIVE_RO_LIST', data: roList });
    };
};

export const recieveMoreRoList = (roList) => {
    return (dispatch) => {
        dispatch({ type: 'RECEIVE_MORE_RO_LIST', data: roList });
    };
};

export const getOpCodes = (params) => async (dispatch) => {
    dispatch({ type: 'GET_OP_CODES' });
    try {
        const response = await clientApi.get(Endpoints.opCodes, {
            params,
        });
        if (response.status === 200) {
            return response;
        } else throw new Error(`Failed to get Op. Codes.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return [];
    }
};
