import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
    {
        id: 'iService',
        title: 'Technicians',
        type: 'item',
        icon: <Icon.User size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/',
    },
    // {
    //     id: 'checkIn',
    //     title: 'CHECKin',
    //     type: 'item',
    //     icon: <Icon.MapPin size={20} />,
    //     permissions: ['admin', 'editor'],
    //     navLink: '/checkin',
    // },
    // { // Is on hold right now
    //   id: "spotLight",
    //   title: "spotLIGHT",
    //   type: "item",
    //   icon: <Icon.Sun size={20} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/spotlight"
    // },
    // {
];

export default navigationConfig;
