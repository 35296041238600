import { store } from '../../storeConfig/store';
import { showSuccess } from '../../../toast';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer';
import { getStorageValue } from '../../../utility/helpers';
import { getRoById } from '.';
import { Capacitor } from '@capacitor/core';

const uploadVideoAndroid = async (params, videoObj, url, token, dispatch) => {
    const fileTransfer = FileTransfer.create();

    let options = {
        fileKey: 'video',
        fileName: videoObj.fileName,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        chunkedMode: false,
        mimeType: 'video/mp4',
        params,
    };

    dispatch({
        type: 'UPDATE_PROGRESS',
        data: 0,
    });

    // use file transfer's onprogress to update the loading progress
    fileTransfer.onProgress((progressEvent) => {
        const { progressValue } = store.getState().app;
        if (progressEvent.lengthComputable) {
            dispatch({
                type: 'UPDATE_PROGRESS',
                data: Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                ),
            });
        } else {
            dispatch({
                type: 'UPDATE_PROGRESS',
                data: progressValue + 1,
            });
        }
    });

    try {
        const res = await fileTransfer.upload(videoObj.fullPath, url, options);
        dispatch({ type: 'UPDATE_PROGRESS', data: 100 });
        await dispatch({ type: 'SEND_VIDEO' });
        if (params.repairOrderID) {
            await dispatch(getRoById(params.repairOrderID));
        }
        dispatch({ type: 'LOADING_END' });
        showSuccess('Video uploaded');
        return res;
    } catch (error) {
        dispatch({ type: 'UPDATE_PROGRESS', data: 0 });
        if (params.repairOrderID) {
            dispatch(getRoById(params.repairOrderID));
        }
        dispatch({ type: 'LOADING_END' });
        throw error; // this error is caught in Video.tsx to show the retry modal
    }
};

const uploadVideoWeb = async (params, videoObj, url, token, dispatch) => {
    const formData = new FormData();
    formData.append('video', videoObj.data, videoObj.fileName);
    formData.append('repairOrderID', params.repairOrderID || ''); // Adjust this as needed

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    };

    dispatch({
        type: 'UPDATE_PROGRESS',
        data: 0,
    });

    try {
        const res = await fetch(url, requestOptions);
        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        dispatch({ type: 'UPDATE_PROGRESS', data: 100 });
        await dispatch({ type: 'SEND_VIDEO' });
        if (params.repairOrderID) {
            await dispatch(getRoById(params.repairOrderID));
        }
        dispatch({ type: 'LOADING_END' });
        showSuccess('Video uploaded');
        return res;
    } catch (error) {
        dispatch({ type: 'UPDATE_PROGRESS', data: 0 });
        if (params.repairOrderID) {
            dispatch(getRoById(params.repairOrderID));
        }
        dispatch({ type: 'LOADING_END' });
        throw error; // this error is caught in Video.tsx to show the retry modal
    }
};

export const uploadVideo =
    (params, videoObj, apiEndpoint) => async (dispatch) => {
        dispatch({ type: 'LOADING' });

        try {
            const appToken = await getStorageValue(
                'iservice_technician_app_token'
            );
            const techToken = await getStorageValue(
                'iservice_technician_tech_token'
            );
            const token = techToken || appToken;
            const subdomain = await getStorageValue(
                'iservice_technician_subdomain'
            );
            const url = `https://${subdomain}.iserviceauto.com/api/${apiEndpoint}`;

            if (Capacitor.getPlatform() === 'web') {
                return uploadVideoWeb(params, videoObj, url, token, dispatch);
            } else {
                return uploadVideoAndroid(
                    params,
                    videoObj,
                    url,
                    token,
                    dispatch
                );
            }
        } catch (error) {
            dispatch({ type: 'LOADING_END' });
            throw error;
        }
    };
